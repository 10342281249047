<template>
  <div class="extration-bdd-frais">
    <!-- <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
        >
          <font-awesome-icon icon="sync-alt" />
        </div>
      </template>
      <span>Actualiser</span>
    </v-tooltip> -->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Actualiser l'écritures comptable des facture(s)
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text mt-2 custom-expansion-ecriture-compta"
        >
          <v-form>
            <!-- SOCIETE  -->
            <!-- <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  :clearable="true"
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filiale_id"
                  :items="[...computedGetVendeurs]"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  return-object
                  item-text="text"
                  dense
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  @change="fetchFiltredFactureComptaAction"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ filiale_id.length - 0 }} société(s)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row class="mt-4 mb-2 ml-2"
              ><b
                >Voulez-vous actualiser l'écriture comptable des facture(s) pour
                cette société ?</b
              >
            </v-row>
            <!-- CLIENT  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="client_id"
                  :items="computedGetVendeurs"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Client"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvé"
                  @change="fetchFiltredFactureComptaAction"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- STATUT  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="type_facture"
                  :items="ListType"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Type"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun Type trouvé"
                  @change="fetchFiltredFactureComptaAction"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="row-top">
              <!-- DATE DEBUT -->
              <v-col cols xs="6" sm="6" md="6" class="date-input-vuetify">
                <v-menu
                  v-model="isDatePickerDebutVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_debut"
                      label="Date début"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="
                        date_debut = null;
                        fetchFiltredFactureComptaAction();
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_debut"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    required
                    @change="fetchFiltredFactureComptaAction"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- DATE FIN -->
              <v-col class="date-input-vuetify " cols xs="6" sm="6" md="6">
                <v-menu
                  v-model="isDatePickerFinVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      label="Date fin"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      v-bind="attrs"
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="
                        date_fin = null;
                        fetchFiltredFactureComptaAction();
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_fin"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="#704ad1"
                    @change="fetchFiltredFactureComptaAction"
                  ></v-date-picker> </v-menu></v-col
            ></v-row>
            <!-- COMPTABILISE FACTURE  -->
            <template v-if="id && date_debut">
              <v-row>
                <v-label class="ml-4 mt-4 mb-3 title-tabel-global"
                  >Liste des factures
                  <b-badge class="custom-badge-cdg" title="Nombre de facture">{{
                    getFiltredFactCompta.length
                  }}</b-badge></v-label
                ></v-row
              >
              <v-progress-circular
                v-if="getLoderFactRefreshCompta"
                indeterminate
                class="ml-2"
                color="#704ad1"
              ></v-progress-circular>

              <v-row
                v-if="
                  getFiltredFactCompta.length == 0 && !getLoderFactRefreshCompta
                "
                class="ml-2"
                >Aucune facture trouvée</v-row
              >
              <v-row
                class=""
                v-if="
                  getFiltredFactCompta.length > 0 && !getLoderFactRefreshCompta
                "
              >
                <v-col cols="6" class="pt-0">
                  <v-checkbox
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="true"
                    :label="'Tous'"
                    hide-details
                    v-model="check_all"
                    @change="handelChangeAll"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row
                class=""
                v-if="
                  getFiltredFactCompta.length > 0 && !getLoderFactRefreshCompta
                "
              >
                <v-col
                  cols="6"
                  class="pt-0"
                  v-for="(option, index) in getFiltredFactCompta"
                  :key="index"
                >
                  <v-checkbox
                    class="checked-tva margin-none"
                    color="#704ad1"
                    :value="option.id"
                    :label="option.num"
                    hide-details
                    v-model="ids"
                  ></v-checkbox> </v-col></v-row
            ></template>
          </v-form>
          <v-row
            class="mt-10 mb-0 ml-4"
            v-if="date_debut == null && date_fin == null"
          >
            Si vous ne choisissez par un filter tous les factures non
            comptabilité va actualiser
          </v-row>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click.prevent.stop="handleRefreshEcriture"
            :loading="loading"
            :class="{ loader: loading }"
          >
            Actualiser
          </v-btn>
          <v-btn text @click="hideModal(true)">
            Non
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { dialogConta: { required: true }, id: { required: true } },
  data() {
    return {
      loading: false,
      error: [],
      dialog: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      date_debut: null,
      date_fin: null,
      ids: [],
      client_id: [],
      type_facture: [],
      loaderFacture: false,
      check_all: false,
      ListType: [
        { value: 'Facture', text: 'Facture' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ]
    };
  },
  methods: {
    ...mapActions([
      'historiqueComptabilisationFacture',
      'exportFileInListExporte',
      'fetchFiltredFactureCompta',
      'resetFiltredFactureCompta',
      'refreshFiltredFactureCompta',
      'getFilialsOfConnectedResponsable'
    ]),
    handelChangeAll() {
      if (this.check_all) {
        this.ids = this.getFiltredFactCompta.map(i => i.id);
      } else {
        this.ids = [];
      }
    },
    handelCheckOne() {
      if (this.ids.length == this.getFiltredFactCompta.length) {
        this.check_all = true;
      } else {
        this.check_all = false;
      }
    },
    async fetchFiltredFactureComptaAction() {
      if (this.id && this.date_debut != null) {
        let payload = {
          filiale_id: [this.id],
          date_debut: this.date_debut
        };
        // if (this.date_debut) {
        //   payload.date_debut = this.date_debut;
        // }
        if (this.date_fin) {
          payload.date_fin = this.date_fin;
        }
        if (this.client_id.length) {
          payload.client_id = this.client_id.map(i => i.value);
        }
        if (this.type_facture.length) {
          payload.type_facture = this.type_facture.map(i => i.value);
        }
        this.ids = [];
        this.loaderFacture = true;
        await this.fetchFiltredFactureCompta(payload);
        this.loaderFacture = false;
      } else {
        if (this.id) {
          this.ids = [];
          this.resetFiltredFactureCompta();
        }
      }
    },
    async openModal() {
      this.dialog = true;
      this.loadingHistorique = true;
      await this.historiqueComptabilisationFacture();
      this.loadingHistorique = false;
    },
    async handleRefreshEcriture() {
      this.loading = true;
      let payload = { refresh_file: true };
      if (this.ids.length) {
        payload.ids = this.ids;
      } else {
        payload.id = this.id;
        if (this.date_fin) {
          payload.date_fin = this.date_fin;
        }
        if (this.date_debut) {
          payload.date_debut = this.date_debut;
        }
        if (this.client_id.length > 0) {
          payload.client_id = this.client_id.map(i => i.value);
        }
        if (this.type_facture.length > 0) {
          payload.type_facture = this.type_facture.map(i => i.value);
        }
      }
      this.$emit('refreshCompta', payload);
      this.loading = false;
      // const response = await this.refreshFiltredFactureCompta(payload);
      // if (response) {
      //   this.loading = false;
      //   this.dialog = false;
      //   this.resetModal();
      // } else {
      //   this.loading = false;
      //   this.error = 'Une erreur est survenue';
      // }
    },
    hideModal(confirm) {
      if (confirm) {
        this.$emit('refreshCompta', { refresh_file: false });
      }
      this.dialog = false;
      this.resetModal();
    },
    resetModal() {
      this.loading = false;
      this.error = [];
      this.date_debut = null;
      this.date_fin = null;
      this.ids = [];
      this.check_all = false;
      this.client_id = [];
      this.type_facture = [];
    }
  },
  computed: {
    ...mapGetters([
      'getHistoriqueComptabilisationFacture',
      'getFiliaeOfResponsable',
      'getFiltredFactCompta',
      'getLoderFactRefreshCompta'
    ]),
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    }
  },
  components: {},
  mounted() {},
  watch: {
    dialogConta() {
      if (this.dialog != this.dialogConta) {
        this.dialog = this.dialogConta;
      }
      if (this.dialog == true) {
        this.getFilialsOfConnectedResponsable();
      }
    }
  }
};
</script>
<style lang="scss">
.custom-expansion-ecriture-compta {
  .v-select .v-label,
  .v-input--checkbox .v-label {
    font-size: 12px;
  }
}
</style>
